import { format } from "@formkit/tempo"
import clientHttp from '../tools/clientHttp'
import UserProfile from "../data";
const user = UserProfile.getUserData();
const URL = "/requests";
const URL_MASSIVE = "/requests/massive-request-item";
let requestsItems = [];
let langDate = "es";
let requests = [];

export const saveRequest = async (request) => {
    return clientHttp.post(`${URL}`, request)
}

export const updateRequestItem = async (request) => {
    return clientHttp.put(`${URL_MASSIVE}`, request)
}

export const updateRequest = async (request) => {
    return clientHttp.patch(`${URL}/${request.id}`, request)
}

export const getRequests = async () => {
    //if(requests.length > 0) return requests;
    let allRequests = (await clientHttp.get(`${URL}`)).data.map(request => ({
        ...request,
        requestDate: format(request.requestDate, "DD-MM-YYYY", langDate)
    }));
    if (user.level === 'user') {
        allRequests = allRequests.filter(request => request.user.id === user.id);
    }
    //requests = allRequests;
    return allRequests;
}

export const getRequestById = async (id) => {
    return clientHttp.get(`${URL}/${id}`)
}

export const getRequestByStatus = async (statuses, requests = false) => {
    console.log("getRequestByStatus", statuses);
    const allRequests = requests ? requests : await getRequests();
    const statusArray = Array.isArray(statuses) ? statuses : [statuses];
    return allRequests.filter(request => 
        statusArray.some(status => 
            request.status.toLowerCase() === status.toLowerCase()
        )
    );
}

export const getRequestByStatusAndQuoteRequest = async () => {
    const allRequests = await getRequestByStatus(['AUTORIZADO']);
    return allRequests.filter(request => 
        !request.purchaseOrder
    );
}

export const getRequestByStatusAndPurchaseOrder = async () => {
    const allRequests = await getRequestByStatus(['AUTORIZADO', 'RECIBIDO']);
    return allRequests.filter(request => 
        request.purchaseOrder
    );
}

export const cruceProducts = async (request) => {
    return clientHttp.post(`${URL}/cruce-products`, request)
}