import { fetchWithCache, saveToCache } from '../tools/sessionCache'
const URL = "/products";
let productsItems = [];

export const updatedCacheProducts = async (data) => {
    await saveToCache(btoa(URL), data);
}

export const getProducts = async () => {   
    const { data, dataCached } = await fetchWithCache(URL);
    if(productsItems.length === 0 || !dataCached){        
        productsItems = data.map(product => ({
            text: `${product.description}`,
            value: product.id
        }))
    }
    return {
        products: data, productsItems
    }
}

export const getProductsItems = async () => {
    return productsItems.length > 0 ? productsItems : (await getProducts()).productsItems;
}

export const getProductsByCategoryAndSex = async (categoryId, sex, size = null) => {
    let productsFiltered = await getFilteredProducts({
        categoryId: categoryId,
        sex,
        size
    })
    //console.log("productsFiltered001", productsFiltered)
    if(productsFiltered.length === 0){
        productsFiltered = await getFilteredProducts({
            categoryId: categoryId,
            sex: 'U',
            size
        })
        //console.log("productsFiltered002", productsFiltered)
    }
    return productsFiltered
}

export const getFilteredProducts = async (filters = {}) => {
    const { products } = await getProducts();
    
    return products.filter(product => {
        return Object.entries(filters).every(([key, value]) => {
            console.log('key', key, 'value', value)
            if (value === undefined || value === null || value === '') return true;
            const productValue = product[key];
            console.log('productValue', productValue)
            if (productValue === null || productValue === undefined) return false;
            if (typeof productValue === 'string' && typeof value === 'string') {
                return productValue.toLowerCase() === value.toLowerCase();
            } else {
                return productValue === value;
            }
        });
    }).map(product => ({
        text: `${product.description}`,
        value: product.id,
        existence: product.inventory?.quantity || 0
    }));
}

export const getSex = () => {
    return [
        { text: "MASCULINO", value: "M" },
        { text: "FEMENINO", value: "F" },
        { text: "UNISEX", value: "U" },
    ];
}